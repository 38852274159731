<template>
  <div style="background: #f6f7fb">
    <Van-Navbar :title="lang.route_course"> </Van-Navbar>
    <van-tabs
      :class="language == 'en' ? 'en_tab' : ''"
      :active="activeTabs"
      @change="changeStatusTab"
    >
      <van-tab
        v-for="(item, index) in courseType"
        :key="index"
        :title="lang[item.lang]"
      ></van-tab>
    </van-tabs>
    <div class="dateTypeBtn">
      <van-button
        round
        plain
        type="primary"
        size="mini"
        :class="currentDateType == item.value ? 'selectcurrent' : ''"
        v-for="(item, index) in dateType"
        :key="index"
        @click="selectDateType(item, index)"
        >{{ lang[item.lang] }}
      </van-button>
    </div>
    <van-pull-refresh
      v-model="refreshLoading"
      @refresh="onRefresh"
      :loading-text="lang.loading"
      :loosing-text="lang.loosingtext"
      :pulling-text="lang.pullingtext"
    >
      <van-list
        class="vanlist"
        :loading="loading"
        :finished="finished"
        :finished-text="lang.noMore"
        :loading-text="lang.loading"
      >
        <coursecard
          v-for="item in list"
          :key="item.lesson_id"
          :course="item"
          :type="'courseList'"
          @onLoad="onLoad"
        >
        </coursecard>
      </van-list>
    </van-pull-refresh>
    <Van-Tabbar />
  </div>
</template>

<script>
import { reactive, toRefs, ref, onMounted, onActivated } from "vue";
import Common from "@/library/Common";
import Config from "@/library/Config";
import request from "@/library/MyRequest";
import Page from "@/library/Page";
import VanNavbar from "@/components/common/van-navbar";
import VanTabbar from "@/components/common/van-tabbar";
import coursecard from "@/components/course/coursecard";

export default {
  name: "ClassEvaluation",
  components: {
    VanNavbar,
    VanTabbar,
    coursecard,
  },
  setup() {
    const minRow = 6;
    const common = Common;
    let state = reactive({
      lang: {
        route_course: "",
        langBtn: "",
        type_All: "",
        type_makeup: "",
        type_review: "",
        type_reviewed: "",
        noMore: "",
        typecourse_day: "",
        typecourse_week: "",
        typecourse_month: "",
        loading: "",
        pullingtext: "",
        loosingtext: "",
      },
      courseType: [
        {
          name: "全部",
          value: 0,
          lang: "type_All",
        },
        {
          name: "未签到",
          value: 1,
          lang: "type_makeup",
        },
        {
          name: "待点评",
          value: 2,
          lang: "type_review",
        },
        {
          name: "已点评",
          value: 3,
          lang: "type_reviewed",
        },
      ],
      dateType: [
        {
          name: "今日课程",
          value: 1,
          lang: "typecourse_day",
        },
        {
          name: "本周课程",
          value: 2,
          lang: "typecourse_week",
        },
        {
          name: "本月课程",
          value: 3,
          lang: "typecourse_month",
        },
      ],
      loading: false,
      refreshLoading: false,
      finished: true,
      activeTabs: 0,
      currentDateType: 1,
      language: common.getLocal("Language"),
    });
    common.initLanguage(state.lang);
    state.lang.langBtn = common.getLanguageHomeBtn();
    onMounted(() => {
      window.addEventListener("setItemEvent", function(e) {
        if (e.key === "Language") {
          setTimeout(() => {
            state.language = common.getLocal("Language");
            common.initLanguage(state.lang);
            state.lang.langBtn = common.getLanguageHomeBtn();
          }, 300);
        }
      });
    });
    const toggleLanguage = function() {
      common.toggleLanguage();
      common.initLanguage(state.lang);
      state.lang.langBtn = common.getLanguageHomeBtn();
    };
    const onLoad = async () => {
      state.loading = true;
      state.finished = false;
      state.list = [];
      let currentStatusTab = state.courseType[state.activeTabs];
      let currentStatus = "";
      if (currentStatusTab["value"] != 0) {
        currentStatus = `&lesson_status=${currentStatusTab["value"]}`;
      }
      var lesson = await request.get(
        `/lesson/list-lesson/?time_type=${state.currentDateType}${currentStatus}`
      );
      state.loading = false;
      state.refreshLoading = false;
      state.finished = true;
      console.log("lesson", lesson);
      state.list = lesson.data.lessons;
    };
    const selectDateType = (item, index) => {
      state.currentDateType = item.value;
      onLoad();
    };
    const changeStatusTab = (e) => {
      state.activeTabs = e;
      onLoad();
    };
    const onRefresh = () => {
      state.refreshLoading = true;
      onLoad();
    };
    const refreshData = async () => {
      let currentStatusTab = state.courseType[state.activeTabs];
      let currentStatus = "";
      if (currentStatusTab["value"] != 0) {
        currentStatus = `&lesson_status=${currentStatusTab["value"]}`;
      }
      var lesson = await request.get(
        `/lesson/list-lesson/?time_type=${state.currentDateType}${currentStatus}`
      );
      state.list = lesson.data.lessons;
    };
    onLoad();
    onActivated(() => {
      refreshData();
    });
    return {
      ...toRefs(state),
      common,
      onLoad,
      toggleLanguage,
      selectDateType,
      changeStatusTab,
      onRefresh,
      refreshData,
    };
  },
};
</script>

<style lang="less" scoped>
.vanlist {
  background: #f6f7fb;
  margin-bottom: 30px;
}

.dateTypeBtn {
  display: flex;
  align-items: center;
  padding: 30px 20px 10px;
  overflow-x: auto;

  /deep/.van-button {
    margin-right: 20px;
    padding: 10px 20px;
    font-size: 24px;
  }

  /deep/ .van-button--primary {
    color: #c1c1c1;
    border-color: #c1c1c1;
  }

  .selectcurrent {
    color: #63472e;
    border-color: #a5907e;
    background: #fff3e8;
  }
}

.en_tab {
  /deep/.van-tab {
    width: auto;
    font-size: 28px;
    line-height: 32px;
    flex: content;
  }

  /deep/.van-tab__text--ellipsis {
    -webkit-line-clamp: 2;
  }
}
</style>
