export default class Page{
    constructor(pageSize){
        this.setPageSize(pageSize);
        this.initData();
        this.list = null;
    }

    initData(){
        this.toTail = false;
        this.cuttentPage = 0;
    }

    setPageSize(pageSize){
        this.pageSize = pageSize;
    }

    setList(array){
        this.initData();
        this.list = array;
        if(this.list.length>this.pageSize){
            return this.getShowList();
        }else{
            this.toTail = true;
            return this.list;
        }
    }

    getShowList(){
        if(this.toTail) return [];
        let startIndex = this.cuttentPage * this.pageSize;
        let endIndex = this.cuttentPage * this.pageSize + this.pageSize;
    
        let _list = this.list.filter((item,index)=>{
            if(index>=startIndex && index<endIndex){
              return item;
            }
        });
        if(_list.length == 0){
          this.toTail = true;
        }

        this.cuttentPage ++;
        return _list;
    }

    addInList(array){
        if(this.list!=null){
            let _list = this.getShowList();
            for(let item of _list){
                array.push(item);
            }
        }
    }
}